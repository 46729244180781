import { Classes } from "@blueprintjs/core";
import {
  Popover2,
  Popover2Props,
  Classes as PopoverClasses,
} from "@blueprintjs/popover2";
import classNames from "classnames";
import React from "react";
import styled, { css } from "styled-components";

import { inputStyles } from "./HexInput";
import { menuStyles } from "./HexMenuItem";

interface HexPopoverProps extends Popover2Props {
  dataCy?: string;
  scrollContent?: boolean;
}

interface HexPortalPopoverProps extends Popover2Props {
  realClassName?: string;
  dataCy?: string;
}

/**
 * This component is an intermediate component that allows us to use
 * styled components with a class prop that isn't `className`.
 *
 * We bascially just save off the consumer `className` to `realClassName`, applying the
 * styles added by styled components to `popoverClassName`.
 */
const HexPortalPopover: React.ComponentType<HexPortalPopoverProps> = React.memo(
  function HexPortalPopover({
    className,
    dataCy,
    popoverClassName,
    realClassName,
    ...props
  }) {
    return (
      <Popover2
        {...props}
        className={realClassName}
        data-cy={dataCy}
        // make sure we don't lose the `popoverClassName` added by the consumer
        popoverClassName={classNames(className, popoverClassName)}
      />
    );
  },
);

export const popoverStyles = css<{ $scrollContent?: boolean }>`
  background: ${({ theme }) => theme.backgroundColor.DEFAULT};
  box-shadow: ${({ theme }) => theme.boxShadow.POPOVER};
  transform: scale(1) !important;

  .${Classes.MENU_HEADER} {
    .${Classes.HEADING} {
      color: ${({ theme }) => theme.fontColor.MUTED};
      font-weight: ${({ theme }) => theme.fontWeight.SEMI_BOLD};
      font-size: ${({ theme }) => theme.fontSize.SMALL};
      text-transform: uppercase;
    }
  }

  .${PopoverClasses.POPOVER2_CONTENT}, .${Classes.POPOVER_CONTENT} {
    background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};
  }

  .${PopoverClasses.POPOVER2_ARROW}, .${Classes.POPOVER_ARROW} {
    &::before {
      box-shadow: ${({ theme }) => theme.boxShadow.POPOVER};
    }
    svg > path:last-child {
      fill: ${({ theme }) => theme.backgroundColor.DEFAULT};
    }
  }

  .${Classes.INPUT_GROUP} {
    input {
      ${inputStyles}
    }
  }

  .${Classes.MENU} {
    ${menuStyles}
    overflow-x: hidden;
  }
`;

const StyledHexPortalPopover = styled(HexPortalPopover)<{
  $scrollContent?: boolean;
}>`
  ${popoverStyles}
`;

export const HexPopover: React.ComponentType<HexPopoverProps> = React.memo(
  function HexPopover({ className, dataCy, scrollContent, ...props }) {
    return (
      <StyledHexPortalPopover
        {...props}
        $scrollContent={scrollContent}
        data-cy={dataCy}
        realClassName={className}
      />
    );
  },
);

/**
 * Passes className in to popoverClassName so that styled-components styles
 * will apply to the popover rather than the target.
 */
export const StyleableHexPopover: React.ComponentType<HexPopoverProps> = ({
  className,
  popoverClassName,
  ...props
}) => (
  <HexPopover
    {...props}
    popoverClassName={classNames(className, popoverClassName)}
  />
);
