import { Classes, KeyCombo, KeyComboTagProps } from "@blueprintjs/core";
import React from "react";
import styled, { css } from "styled-components";

import { HexTooltip } from ".";

interface HexKeyComboProps extends KeyComboTagProps {
  showEscKey?: boolean;
  vimMode?: boolean;
}

const StyledHexKeyCombo = styled(KeyCombo)<HexKeyComboProps>`
  && {
    display: inline-flex;
    gap: 3px;
    justify-content: center;
    min-width: 20px;
    height: 20px;

    color: ${({ theme }) => theme.fontColor.MUTED};
    font-size: ${({ theme }) => theme.fontSize.EXTRA_SMALL};

    text-transform: uppercase;

    ${({ minimal, theme }) =>
      minimal &&
      css`
        padding: 0 4px;

        border: 1px solid ${theme.borderColor.MUTED};
        border-radius: ${theme.borderRadius};

        > .${Classes.ICON} {
          margin: 0;

          > svg {
            width: 12px;
            height: 12px;

            color: ${theme.iconColor};
          }
        }
      `}
  }
`;

const EscKeyCombo = styled(StyledHexKeyCombo)<{ $vimMode: boolean }>`
  && {
    width: ${({ $vimMode }) => ($vimMode ? "50px" : "29px")};
  }
`;

const StyledHexKeyComboWrapper = styled.div`
  && {
    position: relative;

    display: flex;
    gap: 4px;
    align-items: center;

    svg {
      color: inherit;
    }
    font-size: ${({ theme }) => theme.fontSize.SMALL};
    color: ${({ theme }) => theme.fontColor.MUTED};
  }
`;

export const HexKeyCombo: React.ComponentType<HexKeyComboProps> = React.memo(
  function HexKeyCombo({ showEscKey, vimMode, ...props }) {
    if (showEscKey) {
      return (
        <HexTooltip
          autoFocus={false}
          content={`${vimMode ? "Shift+" : ""}Esc brings you to Command mode`}
          enforceFocus={false}
          openOnTargetFocus={false}
        >
          <StyledHexKeyComboWrapper>
            <EscKeyCombo
              $vimMode={vimMode ?? false}
              combo={`${vimMode ? "shift+" : ""}esc`}
              minimal={true}
            />
            then
            <StyledHexKeyCombo {...props} minimal={true} />
          </StyledHexKeyComboWrapper>
        </HexTooltip>
      );
    }

    return <StyledHexKeyCombo {...props} minimal={true} />;
  },
);

export const MinimalKeyCombo = styled(HexKeyCombo)`
  && {
    min-height: 0;
    padding: 2px 0 0 0;
    height: 16px;

    border: none;
    opacity: 0.6;
  }
`;

export const HexKeyComboLabel: React.ComponentType<
  HexKeyComboProps & { label: string }
> = React.memo(function HexKeyComboLabel({ label, ...props }) {
  return (
    <div css="display:flex; align-items:center; gap:6px;">
      <span>{label}</span>
      <HexKeyCombo {...props} />
    </div>
  );
});
