import { AnchorButton, ButtonProps } from "@blueprintjs/core";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { useOnClickProps } from "../hooks/useOnClickProps";

import { HexAnchorButton, HexButtonProps } from "./HexButton";

type HexLinkProps = Pick<
  React.ComponentProps<Link>,
  "to" | "target" | "replace"
>;

type HexAnchorButtonProps = React.ComponentProps<typeof HexAnchorButton>;

export const HexLinkButton = React.forwardRef<
  AnchorButton,
  HexButtonProps &
    ButtonProps<HTMLAnchorElement> &
    HexLinkProps & { children?: React.ReactNode } & Pick<
      HexAnchorButtonProps,
      "rel" | "rightIcon" | "small" | "subtle"
    >
>(function HexLinkButton(
  { children, onClick, replace, target, to, ...otherProps },
  ref,
) {
  const linkProps = useOnClickProps({
    replace,
    onClick,
    target,
    to,
  });
  return (
    <HexAnchorButton {...otherProps} {...linkProps} ref={ref}>
      {children}
    </HexAnchorButton>
  );
});

export const ResetAnchor = styled.a`
  ${({ theme }) => theme.resets.a}
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

export const HexCleanLink = React.forwardRef<
  HTMLAnchorElement,
  HexLinkProps & {
    className?: string;
    children?: React.ReactNode;
    onContextMenu?: React.MouseEventHandler<HTMLAnchorElement>;
    onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLElement>;
  }
>(function HexCleanLink(
  { children, className, onClick, onContextMenu, replace, target, to },
  ref,
) {
  const linkProps = useOnClickProps({
    replace,
    target,
    to,
    onClick,
  });
  return (
    <ResetAnchor
      className={className}
      {...linkProps}
      ref={ref}
      onContextMenu={onContextMenu}
    >
      {children}
    </ResetAnchor>
  );
});
