import { Classes, Dialog, DialogProps } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";
import styled from "styled-components";

import { CrossIcon } from "../components/icons/CustomIcons";

import { HexButton, buttonStyles } from "./HexButton";

interface HexPortalDialogProps extends DialogProps {
  realClassName?: string;
}

/**
 * This component is an intermediate component that allows us to use
 * styled components with a class prop that isn't `className`.
 *
 * We bascially just save off the consumer `className` to `realClassName`, applying the
 * styles added by styled components to `portalClassName`.
 */
const HexPortalDialog: React.ComponentType<HexPortalDialogProps> = React.memo(
  function HexPortalDialog({
    className,
    isCloseButtonShown,
    portalClassName,
    realClassName,
    title,
    ...props
  }) {
    const dialogTitle =
      title || isCloseButtonShown ? (
        <>
          {title}
          {isCloseButtonShown && (
            <HexButton
              css={`
                margin-left: auto;
              `}
              icon={<CrossIcon />}
              minimal={true}
              small={true}
              onClick={props.onClose}
            />
          )}
        </>
      ) : undefined;

    return (
      <Dialog
        {...props}
        className={realClassName}
        isCloseButtonShown={dialogTitle != null ? false : undefined}
        // make sure we don't lose the `portalClassName` added by the consumer
        portalClassName={classNames(className, portalClassName)}
        title={dialogTitle}
      />
    );
  },
);

const StyledHexPortalDialog = styled(HexPortalDialog)`
  > .${Classes.OVERLAY} {
    > .${Classes.DIALOG_CONTAINER} {
      > .${Classes.DIALOG} {
        background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};
        border-radius: ${({ theme }) => theme.borderRadius};
        box-shadow: ${({ theme }) => theme.boxShadow.DIALOG};
        transform: scale(1) !important;

        transition: transform ${({ theme }) => theme.animation.duration}
          ${({ theme }) => theme.animation.easing};

        .${Classes.DIALOG_HEADER} {
          background-color: transparent;
          display: flex;
          align-items: center;
          height: 48px;
          padding: 16px 20px 0px 20px;
          box-shadow: none;

          > .${Classes.HEADING} {
            font-size: ${({ theme }) => theme.fontSize.HEADING_FOUR};
            letter-spacing: ${({ theme }) => theme.letterSpacing.HEADING};
            color: ${({ theme }) => theme.fontColor.MUTED};
            font-weight: ${({ theme }) => theme.fontWeight.NORMAL};
            display: flex;
            align-items: center;
            margin: 0;
          }

          > .${Classes.BUTTON} {
            ${buttonStyles}
          }
        }
        .${Classes.DIALOG_BODY} {
          margin: 0;
          padding: 20px;
        }
        .${Classes.DIALOG_FOOTER} {
          margin: 0;
          padding: 0 20px;
        }
      }
    }
    > .${Classes.OVERLAY_BACKDROP} {
      background-color: ${({ theme }) => theme.backgroundColor.OVERLAY};
    }
  }
`;

/**
 * https://blueprintjs.com/docs/#core/components/dialog
 */
export const HexDialog: React.ComponentType<DialogProps> = React.memo(
  function HexDialog({ className, ...props }) {
    return <StyledHexPortalDialog {...props} realClassName={className} />;
  },
);
