import { Classes, SpinnerProps, SpinnerSize } from "@blueprintjs/core";
import classNames from "classnames";
import { rgba } from "polished";
import React, { ReactNode } from "react";
import styled from "styled-components";

import { CyData } from "../util/cypress";

const SPINNER_LARGE_SVG = (
  <svg
    fill="none"
    height="40"
    viewBox="0 0 40 40"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M17 4C17 2.34315 18.3431 1 20 1C21.6569 1 23 2.34315 23 4C23 5.65685 21.6569 7 20 7C18.3431 7 17 5.65685 17 4ZM20 0C17.7909 0 16 1.79086 16 4C16 6.20914 17.7909 8 20 8C22.2091 8 24 6.20914 24 4C24 1.79086 22.2091 0 20 0ZM17 36C17 34.3431 18.3431 33 20 33C21.6569 33 23 34.3431 23 36C23 37.6569 21.6569 39 20 39C18.3431 39 17 37.6569 17 36ZM20 32C17.7909 32 16 33.7909 16 36C16 38.2091 17.7909 40 20 40C22.2091 40 24 38.2091 24 36C24 33.7909 22.2091 32 20 32ZM9 28C7.34315 28 6 29.3431 6 31C6 32.6569 7.34315 34 9 34C10.6569 34 12 32.6569 12 31C12 29.3431 10.6569 28 9 28ZM5 31C5 28.7909 6.79086 27 9 27C11.2091 27 13 28.7909 13 31C13 33.2091 11.2091 35 9 35C6.79086 35 5 33.2091 5 31ZM1 20C1 18.3431 2.34315 17 4 17C5.65685 17 7 18.3431 7 20C7 21.6569 5.65685 23 4 23C2.34315 23 1 21.6569 1 20ZM4 16C1.79086 16 0 17.7909 0 20C0 22.2091 1.79086 24 4 24C6.20914 24 8 22.2091 8 20C8 17.7909 6.20914 16 4 16ZM36 17C34.3431 17 33 18.3431 33 20C33 21.6569 34.3431 23 36 23C37.6569 23 39 21.6569 39 20C39 18.3431 37.6569 17 36 17ZM32 20C32 17.7909 33.7909 16 36 16C38.2091 16 40 17.7909 40 20C40 22.2091 38.2091 24 36 24C33.7909 24 32 22.2091 32 20ZM28 31C28 29.3431 29.3431 28 31 28C32.6569 28 34 29.3431 34 31C34 32.6569 32.6569 34 31 34C29.3431 34 28 32.6569 28 31ZM31 27C28.7909 27 27 28.7909 27 31C27 33.2091 28.7909 35 31 35C33.2091 35 35 33.2091 35 31C35 28.7909 33.2091 27 31 27ZM31 6C29.3431 6 28 7.34315 28 9C28 10.6569 29.3431 12 31 12C32.6569 12 34 10.6569 34 9C34 7.34315 32.6569 6 31 6ZM27 9C27 6.79086 28.7909 5 31 5C33.2091 5 35 6.79086 35 9C35 11.2091 33.2091 13 31 13C28.7909 13 27 11.2091 27 9ZM6 9C6 7.34315 7.34315 6 9 6C10.6569 6 12 7.34315 12 9C12 10.6569 10.6569 12 9 12C7.34315 12 6 10.6569 6 9ZM9 5C6.79086 5 5 6.79086 5 9C5 11.2091 6.79086 13 9 13C11.2091 13 13 11.2091 13 9C13 6.79086 11.2091 5 9 5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

const SPINNER_DEFAULT_SVG = (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M7 2C7 1.44772 7.44772 1 8 1C8.55228 1 9 1.44772 9 2C9 2.55228 8.55228 3 8 3C7.44772 3 7 2.55228 7 2ZM8 0C6.89543 0 6 0.89543 6 2C6 3.10457 6.89543 4 8 4C9.10457 4 10 3.10457 10 2C10 0.89543 9.10457 0 8 0ZM7 14C7 13.4477 7.44772 13 8 13C8.55228 13 9 13.4477 9 14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14ZM8 12C6.89543 12 6 12.8954 6 14C6 15.1046 6.89543 16 8 16C9.10457 16 10 15.1046 10 14C10 12.8954 9.10457 12 8 12ZM3 10C2.44772 10 2 10.4477 2 11C2 11.5523 2.44772 12 3 12C3.55228 12 4 11.5523 4 11C4 10.4477 3.55228 10 3 10ZM1 11C1 9.89543 1.89543 9 3 9C4.10457 9 5 9.89543 5 11C5 12.1046 4.10457 13 3 13C1.89543 13 1 12.1046 1 11ZM2 5C2 4.44772 2.44772 4 3 4C3.55228 4 4 4.44772 4 5C4 5.55228 3.55228 6 3 6C2.44772 6 2 5.55228 2 5ZM3 3C1.89543 3 1 3.89543 1 5C1 6.10457 1.89543 7 3 7C4.10457 7 5 6.10457 5 5C5 3.89543 4.10457 3 3 3ZM13 10C12.4477 10 12 10.4477 12 11C12 11.5523 12.4477 12 13 12C13.5523 12 14 11.5523 14 11C14 10.4477 13.5523 10 13 10ZM11 11C11 9.89543 11.8954 9 13 9C14.1046 9 15 9.89543 15 11C15 12.1046 14.1046 13 13 13C11.8954 13 11 12.1046 11 11ZM12 5C12 4.44772 12.4477 4 13 4C13.5523 4 14 4.44772 14 5C14 5.55228 13.5523 6 13 6C12.4477 6 12 5.55228 12 5ZM13 3C11.8954 3 11 3.89543 11 5C11 6.10457 11.8954 7 13 7C14.1046 7 15 6.10457 15 5C15 3.89543 14.1046 3 13 3Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export const HexSpinnerSizes = {
  STANDARD: SpinnerSize.STANDARD,
  LARGE: SpinnerSize.LARGE,
};

export enum HexSpinnerDescriptionPosition {
  BELOW = "below",
  RIGHT = "right",
}

const SpinnerInner = styled.div<{ $subtleSpinner?: boolean }>`
  animation-duration: ${({ $subtleSpinner }) => ($subtleSpinner ? "4s" : "2s")};

  svg {
    color: ${({ $subtleSpinner, theme }) =>
      $subtleSpinner ? rgba(theme.iconColor, 0.75) : theme.highlightColor};

    path {
      fill-opacity: 1;
    }
  }
`;

const SpinnerWrapper = styled.div<{
  $size?: number;
  $descriptionPosition?: HexSpinnerDescriptionPosition;
}>`
  display: flex;
  flex: 1 1 auto;

  ${({ $descriptionPosition }) =>
    $descriptionPosition === HexSpinnerDescriptionPosition.BELOW &&
    `flex-direction: column; gap: 10px;`}

  align-items: center;

  > ${SpinnerInner} {
    width: 16px;
    height: 16px;

    ${({ $size }) =>
      $size === HexSpinnerSizes.LARGE && `width: 40px; height: 40px;`}
  }
`;

const Description = styled.div`
  display: flex;
  margin-left: 7px;

  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};

  line-height: 18px;

  text-align: center;
`;

export type HexSpinnerProps = SpinnerProps & {
  description?: ReactNode;
  descriptionPosition?: HexSpinnerDescriptionPosition;
  subtleSpinner?: boolean;
  cyData?: string;
};

export const HexSpinner: React.ComponentType<HexSpinnerProps> = React.memo(
  function HexSpinner({
    className,
    cyData,
    description,
    descriptionPosition = HexSpinnerDescriptionPosition.RIGHT,
    size,
    subtleSpinner = false,
    ...props
  }) {
    return (
      <SpinnerWrapper
        {...props}
        $descriptionPosition={descriptionPosition}
        $size={size}
        className={classNames(Classes.SPINNER, className)}
        data-cy={cyData ?? CyData.HEX_SPINNER}
      >
        <SpinnerInner
          $subtleSpinner={subtleSpinner}
          className={Classes.SPINNER_ANIMATION}
        >
          {(size ?? 0) >= HexSpinnerSizes.LARGE
            ? SPINNER_LARGE_SVG
            : SPINNER_DEFAULT_SVG}
        </SpinnerInner>
        {description && <Description>{description}</Description>}
      </SpinnerWrapper>
    );
  },
);
